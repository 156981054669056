<template>
  <div class="chart-container">
    <div
      id="myChart"
      style="width: 100%; height: 500px"
    ></div>
  </div>
</template>

<script>
import 'echarts-gl'
export default {
  components: {},
  props: ['data'],

  data() {
    return {
      myChart: undefined
    }
  },

  created() { },

  mounted() {
    this.myChart = this.$echarts.init(document.getElementById('myChart'))
    this.draw()
    window.onresize = () => {
      this.myChart.resize()
    }
  },

  watch: {
    data: {
      handler(curVal, oldVal) {
        if (curVal) {
          this.data = curVal
          this.draw()
        }
      }
    }
  },

  methods: {
    draw() {
      let attn = this.data.attn
      let data = this.data.data
      let lab = this.data.lab
      var color = [
        '#008000', // green
        '#90EE90', // lightgreen
        '#FFEC8B',
        '#FFA500', // orange
        '#FF6666',
        '#FF0000', // red
        '#FFFFFF'
      ]
      var series = []
      for (var i = 0; i < color.length; i++) {
        series.push({
          type: 'bar3D',
          data: data[i],
          stack: 'stack',
          shading: 'color',
          barMinHeight: 0,
          color: color[i],
          barSize: 1,
          itemStyle: {
            opacity: 1
          }
        })
      }
      let option = {
        xAxis3D: {
          type: 'category',
          data: lab,
          name: 'Value'
          // type: 'value'
        },
        yAxis3D: {
          type: 'category',
          data: attn,
          name: 'Attention'
          // type: 'value'
        },
        zAxis3D: {
          type: 'value',
          name: 'Visit Frequency'
        },
        grid3D: {
          light: {
            main: {
              intensity: 1.2
            },
            ambient: {
              intensity: 0.3
            }
          },
          viewControl: {
            distance: 180
          }
        },
        series: series,
        toolbox: {
          feature: {
            saveAsImage: {
              show: true,
              pixelRatio: 10
            }
          },
          right: 30
        }
      }
      this.myChart.setOption(option, true)
    }
  }
}
</script>
